import contracts from "./contracts";
import { FarmConfig, QuoteToken } from "./types";

const farms: FarmConfig[] = [
  {
    multiplier: "150",
    pid: 0,
    lpSymbol: "VERSA",
    lpAddresses: {
      97: "",
      56: "0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b",
      592: "0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b",
    },
    tokenSymbol: "SYRUP",
    tokenAddresses: {
      97: "",
      56: "0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b",
      592: "0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    multiplier: "200",
    pid: 1,
    lpSymbol: "ASTR-VERSA LP",
    lpAddresses: {
      97: "",
      592: "0x67A8768510c5499CA0Dfd7DebE965b9dF6D3924C", // lp address token-bnb
    },
    tokenSymbol: "VERSA",
    tokenAddresses: {
      97: "",
      592: "0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b", // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    hot: true,
  },

  {
    multiplier: "200",
    pid: 7,
    lpSymbol: "VERSA-DAI LP",
    lpAddresses: {
      97: "",
      592: "0xd160BD47e1b859D3Ae2f238b6452DCF43ffBA436", // lp address token-bnb
    },
    tokenSymbol: "DAI",
    tokenAddresses: {
      97: "",
      592: "0x6De33698e9e9b787e09d3Bd7771ef63557E148bb", // token address
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },

  {
    multiplier: "10",
    pid: 8,
    lpSymbol: "ASTR-ETH LP",
    lpAddresses: {
      97: "",
      592: "0xa875addf70527c6113425c6318221321c0bfe420", // lp address token-bnb
    },
    tokenSymbol: "ETH",
    tokenAddresses: {
      97: "",
      592: "0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c", // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    multiplier: "10",
    pid: 5,
    lpSymbol: "VERSA-BEAST LP",
    lpAddresses: {
      97: "",
      592: "0x5bDE3Ad2cB9fb2Ff70a7F297f07aFd9090F0E5c3", // lp address token-bnb
    },
    tokenSymbol: "BEAST",
    tokenAddresses: {
      97: "",
      592: "0x07E6158bE70e217C7db73522F01e243296b66663", // token address
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },

  {
    multiplier: "10",
    pid: 2,
    lpSymbol: "ASTR-BUSD LP",
    lpAddresses: {
      97: "",
      592: "0xd4a8EbD1cFCA92BfCEA40ac03529989E125dC6CD", // lp address token-bnb
    },
    tokenSymbol: "VERSA",
    tokenAddresses: {
      97: "",
      592: "0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E", // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    multiplier: "10",
    pid: 3,
    lpSymbol: "ASTR-USDC LP",
    lpAddresses: {
      97: "",
      592: "0x7910902e0dee0aa0f50414aa623ddfe82bd33dc1", // lp address token-bnb
    },
    tokenSymbol: "USDC",
    tokenAddresses: {
      97: "",
      592: "0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98", // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    multiplier: "10",
    pid: 4,
    lpSymbol: "ASTR-DAI LP",
    lpAddresses: {
      97: "",
      592: "0xD03396c769787716d74981ce7560c7689dC70e02", // lp address token-bnb
    },
    tokenSymbol: "DAI",
    tokenAddresses: {
      97: "",
      592: "0x6De33698e9e9b787e09d3Bd7771ef63557E148bb", // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    multiplier: "10",
    pid: 6,
    lpSymbol: "ASTR-BNB LP",
    lpAddresses: {
      97: "",
      592: "0x66c4EF7598632cD8652E6CB062E4F20D9eaff3f6", // lp address token-bnb
    },
    tokenSymbol: "BNB",
    tokenAddresses: {
      97: "",
      592: "0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52", // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
];

export default farms;
