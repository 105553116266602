export default {
  cake: {
    97: '',
    56: '0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b',
    592: '0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b',
  },
  syrup: {
    97: '',
    56: '0x45BFf2318e1AA91E10c81739Af7D69687003037A',
    592: '0x45BFf2318e1AA91E10c81739Af7D69687003037A',
  },
  masterChef: {
    97: '',
    56: '0x68861ab83c13f862ebAAa80Cf07dc64ee7c61DDa',
    592: '0x68861ab83c13f862ebAAa80Cf07dc64ee7c61DDa'
  },
  sousChef: {
    97: '',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  wbnb: {
    97: '',
    56: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
    592: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
  },
  lottery: {
    97: '',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    97: '',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    56: '0x5d2B1dCdc9CbcEB57976090E7EB6DA8F99AFf80e',
    592: '0x5d2B1dCdc9CbcEB57976090E7EB6DA8F99AFf80e',
    97: '',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  ust: {
    56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    97: '',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '',
  },
}
