import React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from "styled-system";
import { Card } from '../../@pancakeswap-libs/uikit'

export type CardWrapperProps = SpaceProps;

export const CardWrapper = styled(Card)<CardWrapperProps>`
  position: relative;
  max-width: 436px;
  width: 100%;
  z-index: 5;
  ${space}
`

CardWrapper.defaultProps = {
  mb: 0,
};

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <CardWrapper>{children}</CardWrapper>
}
