import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 256 256" {...props}>
      <g clip-path="url(#clip0_473_191)">
        <path d="M2.84741 128.175C2.84741 103.387 10.1977 79.1567 23.9689 58.5467C37.74 37.9367 57.3135 21.8732 80.2141 12.3874C103.115 2.90171 128.314 0.419814 152.625 5.2556C176.936 10.0914 199.267 22.0277 216.795 39.555C234.322 57.0824 246.258 79.4136 251.094 103.725C255.93 128.036 253.448 153.235 243.962 176.136C234.476 199.036 218.413 218.61 197.803 232.381C177.193 246.152 152.962 253.502 128.175 253.502C94.9359 253.502 63.0584 240.298 39.555 216.795C16.0515 193.291 2.84741 161.414 2.84741 128.175V128.175Z" stroke="url(#paint0_linear_473_191)" stroke-width="5" stroke-linecap="round" />
        <path d="M92.9164 68.1887L127.046 153.712L161.655 68.1887H182.036L126.646 200.87L72.5447 68.1887H92.9164Z" fill="url(#paint1_linear_473_191)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_473_191" x1="4.36605" y1="144.49" x2="256.44" y2="111.26" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9F36A6" />
          <stop offset="1" stop-color="#13CCF9" />
        </linearGradient>
        <linearGradient id="paint1_linear_473_191" x1="52.4627" y1="134.529" x2="183.415" y2="134.529" gradientUnits="userSpaceOnUse">
          <stop stop-color="#BD1493" />
          <stop offset="1" stop-color="#09D6FE" />
        </linearGradient>
        <clipPath id="clip0_473_191">
          <rect width="256" height="256" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
