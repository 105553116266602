import React from 'react';
import { Link } from 'react-router-dom';

import {
  ButtonMenu,
  ButtonMenuItem,
} from '../../../@pancakeswap-libs/uikit';

import useI18n from 'hooks/useI18n';

// eslint-disable-next-line react/require-default-props
function CardNav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n();

  return (
    <div>
      <ButtonMenu
        activeIndex={activeIndex}
        scale="sm"
        variant="subtle"
      >
        <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
          {TranslateString(1142, 'Swap')}
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
          {TranslateString(262, 'Liquidity')}
        </ButtonMenuItem>
        <ButtonMenuItem
          id="pool-nav-link"
          as="a"
          href="https://cbridge.celer.network/#/transfer"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bridge
        </ButtonMenuItem>
      </ButtonMenu>
    </div>
  );
}

export default CardNav;
