import React from "react";
import styled from "styled-components";
import { SvgProps } from "../../../components/Svg";
import Flex from "../../../components/Box/Flex";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Link from "../../../components/Link/Link";
import * as IconModule from "../icons";
import { socials } from "../config";

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const SocialEntry = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const SocialLinks: React.FC = () => (
  <SocialEntry>
    {socials.map((social) => {
      const Icon = Icons[social.icon];
      const iconProps = { width: "24px", color: "textSubtle", style: { cursor: "pointer" } };

      return (
        <SocialLink target="_blank" rel="noopener noreferrer" key={social.label} href={social.href} aria-label={social.label}>
          <Icon {...iconProps} />
        </SocialLink>
      );
    })}
  </SocialEntry>
);

export default React.memo(SocialLinks, () => true);
