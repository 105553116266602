/* eslint-disable react/require-default-props */
import React, { useMemo } from "react";
import styled from "styled-components";
import { Currency, ETHER, Token } from "../../../@pancakeswap-libs/sdk";
import useHttpLocations from "../../hooks/useHttpLocations";
import { WrappedTokenInfo } from "../../state/lists/hooks";
import Logo from "../Logo";
import CoinLogo from "../pancake/CoinLogo";

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${address}/logo.png`;

const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`;

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

const UnknownSymbolLogo = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 2px solid #828282;
  font-weight: bold;
  line-height: 18px;
  color: #828282;
  background-color: white;
  margin-right: 5px;
`;

export default function CurrencyLogo({
  currency,
  size = "24px",
  style,
}: {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
}) {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined
  );

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return [];

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [
          ...uriLocations,
          `/images/coins/${currency?.symbol ?? "token"}.png`,
          getTokenLogoURL(currency.address),
        ];
      }

      return [
        `/images/coins/${currency?.symbol ?? "token"}.png`,
        getTokenLogoURL(currency.address),
      ];
    }
    return [];
  }, [currency, uriLocations]);

  if (currency instanceof Token && currency.symbol === "WASTR") {
    return (
      <StyledBnbLogo src="/images/coins/astar.png" size={size} style={style} />
    );
  }

  if (currency === ETHER) {
    return (
      <StyledBnbLogo src="/images/coins/astar.png" size={size} style={style} />
    );
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol === "VERSA") {
    return (
      <StyledBnbLogo src="/images/coins/versa.png" size={size} style={style} />
    );
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol === "WASTR") {
    return (
      <StyledBnbLogo src="/images/coins/astar.png" size={size} style={style} />
    );
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol === "BUSD") {
    return (
      <StyledBnbLogo src="/images/coins/BUSD.png" size={size} style={style} />
    );
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol === "USDC") {
    return (
      <StyledBnbLogo src="/images/coins/USDC.png" size={size} style={style} />
    );
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol === "DAI") {
    return (
      <StyledBnbLogo src="/images/coins/DAI.png" size={size} style={style} />
    );
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol === "BEAST") {
    return (
      <StyledBnbLogo src="/images/coins/BEAST.png" size={size} style={style} />
    );
  }

  if (currency instanceof WrappedTokenInfo && currency.symbol === "BNB") {
    return (
      <StyledBnbLogo src="/images/coins/bnb.png" size={size} style={style} />
    );
  }

  return (
    <UnknownSymbolLogo>
      <span>{currency.symbol?.[0] || "?"}</span>
    </UnknownSymbolLogo>
  );

  return (
    <StyledBnbLogo
      src={`/images/coins/${currency.symbol}.png`}
      size={size}
      style={style}
    />
  );

  return (currency as any)?.symbol ? (
    <CoinLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
      style={style}
    />
  ) : (
    <StyledLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
      style={style}
    />
  );
}
