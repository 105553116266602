import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g transform="matrix(1 0 0 1 12.01 12)" id="lfv4dNLe-n1n8q36nbSc1">
        <path transform=" translate(-11, -9.23)" d="M 8.27638 17.3439 L 8.61817 12.180670000000001 L 17.992559999999997 3.7339600000000015 C 18.407559999999997 3.3555700000000015 17.90713 3.1724800000000015 17.357839999999996 3.5020500000000014 L 5.786319999999996 10.813580000000002 L 0.7817599999999967 9.226760000000002 C -0.29237000000000335 8.921610000000003 -0.3046000000000033 8.177030000000002 1.0258899999999966 7.639940000000003 L 20.519239999999996 0.12090000000000245 C 21.410289999999996 -0.28190999999999755 22.264729999999997 0.3406200000000025 21.922969999999996 1.7077200000000023 L 18.602879999999995 17.3439 C 18.370969999999996 18.45467 17.699609999999996 18.72322 16.771959999999996 18.210530000000002 L 11.718579999999996 14.475410000000002 L 9.289539999999995 16.831210000000002 C 9.008799999999995 17.111950000000004 8.776889999999995 17.343860000000003 8.276419999999995 17.343860000000003 z" />
      </g>
    </Svg>
  );
};

export default Icon;
