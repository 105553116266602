/* eslint-disable no-nested-ternary */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ArrowDown } from "react-feather";
import styled, { ThemeContext } from "styled-components";

import {
  CardBody,
  ArrowDownIcon,
  Button,
  IconButton,
  Text,
} from "../../../@pancakeswap-libs/uikit";
import {
  CurrencyAmount,
  JSBI,
  Token,
  Trade,
} from "../../../@pancakeswap-libs/sdk";

import Page from "components/layout/Page";

import { TradeHistory } from "packages/dex/components/TradeHistory";
import { CardWrapper } from "../../components/AppBody";
import AddressInputPanel from "../../components/AddressInputPanel";
import Card, { GreyCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import CardNav from "../../components/CardNav";
import { AutoRow, RowBetween } from "../../components/Row";
import TokenWarningModal from "../../components/TokenWarningModal";
import SyrupWarningModal from "../../components/SyrupWarningModal";
import ProgressSteps from "../../components/ProgressSteps";
import { LinkStyledButton } from "../../components/Shared";
import Loader from "../../components/Loader";
import PageHeader from "../../components/PageHeader";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import ConfirmSwapModal from "../../components/swap/ConfirmSwapModal";
import AdvancedSwapDetailsDropdown from "../../components/swap/AdvancedSwapDetailsDropdown";
import confirmPriceImpactWithoutFee from "../../components/swap/confirmPriceImpactWithoutFee";
import TradePrice from "../../components/swap/TradePrice";
import TvChart from "components/TvChart";
import {
  ArrowWrapper,
  BottomGrouping,
  SwapCallbackError,
  Wrapper,
} from "../../components/swap/styleds";

import { INITIAL_ALLOWED_SLIPPAGE } from "../../constants/index";

import useI18n from "hooks/useI18n";
import { useActiveWeb3React } from "../../hooks";
import { useCurrency } from "../../hooks/Tokens";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "../../hooks/useApproveCallback";

import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../utils/prices";

import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../state/swap/hooks";
import { Field } from "../../state/swap/actions";
import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../state/user/hooks";

const PageExtended = styled(Page)<{
  proMode: boolean;
}>`
  ${(props) =>
    props.proMode
      ? `
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 100%;
    padding: 8px;
    padding-top: 12px;
    margin: 0;
    

    @media (min-width:768px) { 
      padding: 12px;
      align-items: unset;
      display: grid;
      max-height: calc(100vh - 64px);
      grid-template-columns: 1fr 1fr 420px;
      grid-template-rows: auto minmax(0, 1fr);
      grid-gap: 12px;
    }
  `
      : `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `};
`;

const TvChartWrapper = styled.div`
  height: 600px;
  width: 100%;

  @media (min-width: 768px) {
    margin: 0;
    height: 100%;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    display: flex;
  }
`;

const CardNavWrapper = styled.div<{
  proMode: boolean;
}>`
  ${(props) =>
    props.proMode
      ? `
    margin: 12px 0;
  `
      : `
    margin-bottom: 24px;
  `};
`;

const SwapWrapper = styled.div<{
  proMode: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
  width: 100%;

  ${(props) =>
    props.proMode
      ? `
    order: 1;
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  `
      : ""};
`;

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  max-height: 400px;

  @media (min-width: 768px) {
    max-height: unset;
    flex-direction: unset;
    align-items: unset;
    max-height: unset;
    margin: 0;

    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
`;

const Swap = () => {
  const loadedUrlParams = useDefaultsFromURLSearch();
  const TranslateString = useI18n();

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(
    false
  );
  const [isSyrup, setIsSyrup] = useState<boolean>(false);
  const [syrupTransactionType, setSyrupTransactionType] = useState<string>("");
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c instanceof Token && c.symbol !== "VERSA"
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  const handleConfirmSyrupWarning = useCallback(() => {
    setIsSyrup(false);
    setSyrupTransactionType("");
  }, []);

  const { account } = useActiveWeb3React();
  const theme = useContext(ThemeContext);

  const [isExpertMode] = useExpertModeManager();

  const [proMode, setProMode] = useState(
    localStorage.getItem("proMode") === "1"
  );

  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    }));
    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }));
      })
      .catch((error) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }));
      });
  }, [priceImpactWithoutFee, swapCallback, setSwapState]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({
      ...prevState,
      showConfirm: false,
    }));

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [onUserInput, txHash, setSwapState]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({
      ...prevState,
      tradeToConfirm: trade,
    }));
  }, [trade]);

  // This will check to see if the user has selected Syrup to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForSyrup = useCallback(
    (selected: string, purchaseType: string) => {
      if (selected === "syrup") {
        setIsSyrup(true);
        setSyrupTransactionType(purchaseType);
      }
    },
    [setIsSyrup, setSyrupTransactionType]
  );

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
      if (inputCurrency.symbol.toLowerCase() === "syrup") {
        checkForSyrup(inputCurrency.symbol.toLowerCase(), "Selling");
      }
    },
    [onCurrencySelection, setApprovalSubmitted, checkForSyrup]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      if (outputCurrency.symbol.toLowerCase() === "syrup") {
        checkForSyrup(outputCurrency.symbol.toLowerCase(), "Buying");
      }
    },
    [onCurrencySelection, checkForSyrup]
  );

  return (
    <PageExtended proMode={proMode}>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <SyrupWarningModal
        isOpen={isSyrup}
        transactionType={syrupTransactionType}
        onConfirm={handleConfirmSyrupWarning}
      />
      {proMode && (
        <TvChartWrapper>
          <TvChart pair={currencies} />
        </TvChartWrapper>
      )}
      <SwapWrapper proMode={proMode}>
        <CardNavWrapper proMode={proMode}>
          <CardNav />
        </CardNavWrapper>
        <CardWrapper>
          <Wrapper id="swap-page">
            <ConfirmSwapModal
              isOpen={showConfirm}
              trade={trade}
              originalTrade={tradeToConfirm}
              onAcceptChanges={handleAcceptChanges}
              attemptingTxn={attemptingTxn}
              txHash={txHash}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onConfirm={handleSwap}
              swapErrorMessage={swapErrorMessage}
              onDismiss={handleConfirmDismiss}
            />
            <PageHeader
              title={TranslateString(8, "Exchange")}
              description={TranslateString(1192, "Trade tokens in an instant")}
              toggleProMode={() => {
                localStorage.setItem("proMode", proMode ? "0" : "1");
                setProMode(!proMode);
              }}
            />
            <CardBody>
              <AutoColumn gap="md">
                <CurrencyInputPanel
                  label={
                    independentField === Field.OUTPUT && !showWrap && trade
                      ? TranslateString(194, "From (estimated)")
                      : TranslateString(76, "From")
                  }
                  value={formattedAmounts[Field.INPUT]}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id="swap-currency-input"
                />
                <AutoColumn justify="space-between">
                  <AutoRow
                    justify={isExpertMode ? "space-between" : "center"}
                    style={{ padding: "0 1rem" }}
                  >
                    <ArrowWrapper clickable>
                      <IconButton
                        variant="tertiary"
                        onClick={() => {
                          setApprovalSubmitted(false); // reset 2 step UI for approvals
                          onSwitchTokens();
                        }}
                        style={{ borderRadius: "50%" }}
                        scale="sm"
                      >
                        <ArrowDownIcon color="primary" width="24px" />
                      </IconButton>
                    </ArrowWrapper>
                    {recipient === null && !showWrap && isExpertMode ? (
                      <LinkStyledButton
                        id="add-recipient-button"
                        onClick={() => onChangeRecipient("")}
                      >
                        + Add a send (optional)
                      </LinkStyledButton>
                    ) : null}
                  </AutoRow>
                </AutoColumn>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={
                    independentField === Field.INPUT && !showWrap && trade
                      ? TranslateString(196, "To (estimated)")
                      : TranslateString(80, "To")
                  }
                  showMaxButton={false}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  id="swap-currency-output"
                />
                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow
                      justify="space-between"
                      style={{ padding: "0 1rem" }}
                    >
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.colors.textSubtle} />
                      </ArrowWrapper>
                      <LinkStyledButton
                        id="remove-recipient-button"
                        onClick={() => onChangeRecipient(null)}
                      >
                        - Remove send
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel
                      id="recipient"
                      value={recipient}
                      onChange={onChangeRecipient}
                    />
                  </>
                ) : null}
                {showWrap ? null : (
                  <Card padding=".25rem .75rem 0 .75rem" borderRadius="20px">
                    <AutoColumn gap="4px">
                      {Boolean(trade) && (
                        <RowBetween align="center">
                          <Text fontSize="14px">
                            {TranslateString(1182, "Price")}
                          </Text>
                          <TradePrice
                            price={trade?.executionPrice}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                          />
                        </RowBetween>
                      )}
                      {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                        <RowBetween align="center">
                          <Text fontSize="14px">
                            {TranslateString(88, "Slippage Tolerance")}
                          </Text>
                          <Text fontSize="14px">{allowedSlippage / 100}%</Text>
                        </RowBetween>
                      )}
                    </AutoColumn>
                  </Card>
                )}
              </AutoColumn>
              <BottomGrouping>
                {!account ? (
                  <ConnectWalletButton width="100%" />
                ) : showWrap ? (
                  <Button
                    disabled={Boolean(wrapInputError)}
                    onClick={onWrap}
                    width="100%"
                  >
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP
                        ? "Wrap"
                        : wrapType === WrapType.UNWRAP
                        ? "Unwrap"
                        : null)}
                  </Button>
                ) : noRoute && userHasSpecifiedInputOutput ? (
                  <GreyCard style={{ textAlign: "center" }}>
                    <Text mb="4px">
                      {TranslateString(
                        1194,
                        "Insufficient liquidity for this trade."
                      )}
                    </Text>
                  </GreyCard>
                ) : showApproveFlow ? (
                  <RowBetween>
                    <Button
                      onClick={approveCallback}
                      disabled={
                        approval !== ApprovalState.NOT_APPROVED ||
                        approvalSubmitted
                      }
                      style={{ width: "48%" }}
                      variant={
                        approval === ApprovalState.APPROVED
                          ? "success"
                          : "primary"
                      }
                    >
                      {approval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          Approving <Loader stroke="white" />
                        </AutoRow>
                      ) : approvalSubmitted &&
                        approval === ApprovalState.APPROVED ? (
                        "Approved"
                      ) : (
                        `Approve ${currencies[Field.INPUT]?.symbol}`
                      )}
                    </Button>
                    <Button
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap();
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined,
                          });
                        }
                      }}
                      style={{ width: "48%" }}
                      id="swap-button"
                      disabled={
                        !isValid ||
                        approval !== ApprovalState.APPROVED ||
                        (priceImpactSeverity > 3 && !isExpertMode)
                      }
                      variant={
                        isValid && priceImpactSeverity > 2
                          ? "danger"
                          : "primary"
                      }
                    >
                      {priceImpactSeverity > 3 && !isExpertMode
                        ? `Price Impact High`
                        : `Swap${priceImpactSeverity > 2 ? " Anyway" : ""}`}
                    </Button>
                  </RowBetween>
                ) : (
                  <Button
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap();
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined,
                        });
                      }
                    }}
                    id="swap-button"
                    disabled={
                      !isValid ||
                      (priceImpactSeverity > 3 && !isExpertMode) ||
                      !!swapCallbackError
                    }
                    variant={
                      isValid && priceImpactSeverity > 2 && !swapCallbackError
                        ? "danger"
                        : "primary"
                    }
                    width="100%"
                  >
                    {swapInputError ||
                      (priceImpactSeverity > 3 && !isExpertMode
                        ? `Price Impact Too High`
                        : `Swap${priceImpactSeverity > 2 ? " Anyway" : ""}`)}
                  </Button>
                )}
                {showApproveFlow && (
                  <ProgressSteps
                    steps={[approval === ApprovalState.APPROVED]}
                  />
                )}
                {isExpertMode && swapErrorMessage ? (
                  <SwapCallbackError error={swapErrorMessage} />
                ) : null}
              </BottomGrouping>
            </CardBody>
          </Wrapper>
        </CardWrapper>
        {trade && <AdvancedSwapDetailsDropdown trade={trade} />}
      </SwapWrapper>
      {proMode && !(trade?.route?.path?.length > 2) && (
        <HistoryWrapper>
          <TradeHistory pair={currencies} />
        </HistoryWrapper>
      )}
    </PageExtended>
  );
};

export default Swap;
