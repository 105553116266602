import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.9806 4.33997C34.828 4.33997 43.6214 13.1335 43.6214 23.9808C43.6214 34.8281 34.828 43.6216 23.9806 43.6216C13.1333 43.6216 4.33984 34.8281 4.33984 23.9808C4.33984 13.1335 13.1333 4.33997 23.9806 4.33997Z" fill="white" stroke="url(#paint0_linear_451_1446)"/>
        <path d="M18.0523 13.4399L24.1681 28.7655L30.3699 13.4399H34.0223L24.0966 37.2159L14.3999 13.4399H18.0523Z" fill="url(#paint1_linear_451_1446)"/>
        <defs>
          <linearGradient id="paint0_linear_451_1446" x1="3.76657" y1="23.9808" x2="44.1946" y2="23.9808" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BD1493"/>
            <stop offset="1" stop-color="#09D6FE"/>
          </linearGradient>
          <linearGradient id="paint1_linear_451_1446" x1="10.802" y1="43.6212" x2="34.2687" y2="43.6212" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BD1493"/>
            <stop offset="1" stop-color="#09D6FE"/>
          </linearGradient>
        </defs>
      </svg>
    </Svg>
  );
};

export default Icon;
