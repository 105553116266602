import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'VERSA',
    tokenAddress: '0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0xB9dEDB74bd7b298aBf76b9dFbE5b62F0aB05a57b',  // token address
    contractAddress: {
      97: '',
      592: '0x68861ab83c13f862ebAAa80Cf07dc64ee7c61DDa',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '0.4',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
