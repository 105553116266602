import React from 'react'
import { Percent } from '../../../@pancakeswap-libs/sdk'

import { ErrorText } from './styleds'

import { ONE_BIPS } from '../../constants'

import { warningSeverity } from '../../utils/prices'


/**
 * Formatted version of price impact text with warning colors
 */
// eslint-disable-next-line react/require-default-props
export default function FormattedPriceImpact({ priceImpact }: { priceImpact?: Percent }) {
  return (
    <ErrorText fontSize="14px" severity={warningSeverity(priceImpact)}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
    </ErrorText>
  )
}
