import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import dayjs from 'dayjs';

import {
  ButtonMenu,
  ButtonMenuItem,
  CardBody,
  CardHeader,
  Skeleton,
  Text,
} from 'packages/@pancakeswap-libs/uikit';
import { Currency } from 'packages/@pancakeswap-libs/sdk/dist';

import { useTradesHistory } from 'subgraph/hooks';

import { CardWrapper } from '../AppBody';

interface Props {
  pair: {
    INPUT?: Currency;
    OUTPUT?: Currency;
  };
}

const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardBodyExtended = styled(CardBody)`
  height: 100%;
  padding: 0;
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HistoryHeader = styled.div`
  padding: 12px 24px 0 24px;
`;

const HistoryBody = styled.div`
  padding: 0 24px 24px 24px;
  overflow-y: scroll;
`;

const HistoryHeaderItem = styled.div`
  display: grid;
  grid-template-columns: 86px minmax(50px, 1fr) 86px;
`;

const HistoryItem = styled.a`
  display: grid;
  grid-template-columns: 86px minmax(50px, 1fr) 86px;
  border-radius: 10px;
  border: 1px solid
    ${({ theme }) => darken(0.06, theme.colors.invertedContrast)};

  &:hover {
    background-color: #e0d9eb;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const HistoryItemStub = styled(HistoryItem)`
  grid-template-columns: 1fr;

  &:hover {
    background-color: unset;
  }
`;

const HistoryItemCell = styled.div`
  padding: 12px 8px;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }
`;

const NavWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getTextColor = (trade: any) => {
  const colorsMap = {
    buy: '#38a169',
    sell: '#E53E3E',
  } as const;

  const color = colorsMap[trade.type];

  return color;
};

const TradeNumber = ({
  value,
  digits = 2,
}: {
  value: string;
  // eslint-disable-next-line react/require-default-props
  digits?: number;
}) => {
  const formattedValue = new Intl.NumberFormat('en-EN', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(parseFloat(value));

  return <span>{formattedValue}</span>;
};

const TradeDate = ({ trade }: { trade: any }) => {
  const Date = styled.div``;

  const DateText = styled.div`
    text-align: right;
  `;

  const dDate = dayjs.unix(parseInt(trade.transaction.timestamp, 10));

  const formattedDate = dDate.format('DD.MM');
  const formattedTime = dDate.format('HH:mm:ss');

  return (
    <Date>
      <DateText>{formattedDate}</DateText>
      <DateText>{formattedTime}</DateText>
    </Date>
  );
};

const TradeAmount = ({ trade }: { trade: any }) => {
  const Amount = styled.div``;

  const AmountRow = styled.div`
    display: flex;
  `;

  const AmountValue = styled.div`
    flex-grow: 1;
    width: calc(50% - 4px);
    margin-right: 8px;
    text-align: right;
    ${truncate}
  `;

  const AmountLabel = styled.div`
    flex-shrink: 0;
    width: calc(50% - 4px);
    text-align: left;
  `;

  return (
    <Amount>
      <AmountRow>
        <AmountValue>
          <TradeNumber value={trade.amount0} digits={6} />
        </AmountValue>
        <AmountLabel>{trade.symbol0}</AmountLabel>
      </AmountRow>
      <AmountRow>
        <AmountValue>
          <TradeNumber value={trade.amount1} digits={6} />
        </AmountValue>
        <AmountLabel>{trade.symbol1}</AmountLabel>
      </AmountRow>
    </Amount>
  );
};

const TradeUsd = ({ trade }: { trade: any }) => {
  const Usd = styled.div`
    ${truncate}
  `;

  return (
    <Usd>
      <TradeNumber value={trade.amountUSD} />
    </Usd>
  );
};

const SkeletonTrades = () => (
  <>
    {Array.from({ length: 10 }).map((val, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Skeleton height={56} key={idx} borderRadius="10px" marginBottom={12} />
    ))}
  </>
);

const TradeHistory = ({ pair }: Props) => {
  const [historyMode, setHistoryMode] = useState('TRADES');
  const { data: trades, loading: tradesLoading } = useTradesHistory(
    pair,
    historyMode === 'LIQUIDITY',
  );

  return (
    <CardWrapper>
      <NavWrapper>
        <ButtonMenu
          activeIndex={historyMode === 'TRADES' ? 0 : 1}
          scale="sm"
          variant="subtle"
          onItemClick={(index) =>
            index === 0 ? setHistoryMode('TRADES') : setHistoryMode('LIQUIDITY')
          }
        >
          <ButtonMenuItem as="button" id="swap-nav-link">
            TRADES
          </ButtonMenuItem>
          <ButtonMenuItem as="button" id="pool-nav-link">
            LIQUIDITY
          </ButtonMenuItem>
        </ButtonMenu>
      </NavWrapper>
      <CardBodyExtended>
        <HistoryContainer>
          <HistoryHeader>
            <HistoryHeaderItem>
              <HistoryItemCell>
                <Text textAlign="left">USD</Text>
              </HistoryItemCell>
              <HistoryItemCell>
                <Text textAlign="center">Amount</Text>
              </HistoryItemCell>
              <HistoryItemCell>
                <Text textAlign="left">Date</Text>
              </HistoryItemCell>
            </HistoryHeaderItem>
          </HistoryHeader>
          <HistoryBody>
            {/* eslint-disable-next-line no-nested-ternary */}
            {tradesLoading ? (
              <SkeletonTrades />
            ) : trades.length ? (
              trades.slice(0, 10).map((trade) => {
                return (
                  <HistoryItem
                    href={`https://blockscout.com/astar/tx/${trade.transaction.id}/token-transfers`}
                    target="_blank"
                    key={trade.transaction.id}
                    style={{ color: getTextColor(trade) }}
                  >
                    <HistoryItemCell>
                      <TradeUsd trade={trade} />
                    </HistoryItemCell>
                    <HistoryItemCell>
                      <TradeAmount trade={trade} />
                    </HistoryItemCell>
                    <HistoryItemCell>
                      <TradeDate trade={trade} />
                    </HistoryItemCell>
                  </HistoryItem>
                );
              })
            ) : (
              <HistoryItemStub>
                <HistoryItemCell>
                  <Text textAlign="center">
                    The history can&apos;t be displayed
                  </Text>
                  <Text textAlign="center">
                    because this pair has no direct liquidity
                  </Text>
                </HistoryItemCell>
              </HistoryItemStub>
            )}
          </HistoryBody>
        </HistoryContainer>
      </CardBodyExtended>
    </CardWrapper>
  );
};

/* temp shit */
export default React.memo(TradeHistory, (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true;
  }
  return false;
});
